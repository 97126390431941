.popup__avatar-fieldset {
  padding: 0;
  border: none;
  margin-top: 19px;
  width: 358px;
}

@media screen and (max-width: 600px) {
  .popup__avatar-fieldset {
    max-width: 320px;
  }
}

@media screen and (max-width: 420px) {
  .popup__avatar-fieldset {
    margin-top: 43px;
    max-width: 250px;
  }
}