.profile__about {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  padding-top: 14px;
  max-width: 450px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media screen and (max-width: 850px) {
  .profile__about  {
    max-width: 400px;
  }
}

@media screen and (max-width: 720px) {
  .profile__about {
    max-width: 220px;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
}

@media screen and (max-width: 420px) {
  .profile__about {
    padding-top: 8px;
    font-size: 14px;
    line-height: 17px;
  }
}