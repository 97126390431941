@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Inter-Regular.woff2) format('woff2'),
       url(../fonts/Inter-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts//Inter-Medium.woff2) format('woff2'),
       url(../fonts/Inter-Medium.woff) format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: url(../fonts/Inter-Black.woff2) format('woff2'),
       url(../fonts/Inter-Black.woff) format('woff');
}