.element__like-button {
  background-image: url(../../../images/Heart.svg);
  width: 22px;
  height: 19px;
  background-color: white;
  background-size: cover;
  background-position: center;
  border: none;
  cursor: pointer;
  transition: opacity 0.5s ease;
}

.element__like-button:hover {
  opacity: 0.5;
}