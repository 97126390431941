.element__delete-button {
  background-image: url(../../../images/Trash.svg);
  width: 18px;
  height: 19px;
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: transparent;
  background-size: cover;
  background-position: center;
  border: none;
  cursor: pointer;
  transition: opacity 0.5s ease;
}

.element__delete-button:hover {
  opacity: 0.5;
}
