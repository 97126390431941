.profile__add-button {
  width: 150px;
  height: 50px;
  background-image: url(../../../images/Plus.svg);
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 2px;
  margin: 0;
  background-position: 50%;
  background-repeat: no-repeat;
  background-color: transparent;
  cursor: pointer;
  transition: opacity 0.5s ease;
}

.profile__add-button:hover {
  opacity: 0.6;
}

@media screen and (max-width: 1024px) {
  .profile__add-button {
    width: calc(100% * (918 / 1024));
  }
}
  
@media screen and (max-width: 480px) {
  .profile__add-button {
    background-image: url(../../../images/Plus320.svg);
    width: 100%;
  }
}