.element__name {
  max-width: 200px;
  margin: 0;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  color: black;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}