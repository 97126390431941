.popup__icon-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  padding: 32px 36px 60px;
  margin: 0;
}

@media screen and (max-width: 460px) {
  .popup__icon-text {
    font-size: 20px;
    line-height: 24px;
    padding: 40px 18px 50px;
  }
}