.popup__input {
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: black;
  border: none;
  width: 100%;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  margin: 30px 0 10px 0;
}

.popup__input:focus {
  content: "";
  outline: none;
}