.popup__icon {
  align-self: center;
  width: 120px;
  height: 120px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 60px auto 0;
}

@media screen and (max-width: 460px) {
  .popup__icon {
    margin-top: 50px;
  }
}