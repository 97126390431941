.profile__edit-button {
  width: 24px;
  height: 24px;
  background-image: url(../../../images/Pen.svg);
  background-size: 10px 10px;
  border: 1px solid #fff;
  margin: 0;
  background-position: 50%;
  background-repeat: no-repeat;
  background-color: transparent;
  cursor: pointer;
  transition: opacity 0.5s ease;
}

.profile__edit-button:hover {
  opacity: 0.6;
}

@media screen and (max-width: 420px) {
  .profile__edit-button {
    width: 18px;
    height: 18px;
    }
  }