.sign-page__input {
  border: none;
  background-color: transparent;
  width: 100%;
  max-width: 358px;
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  width: 100%;
  border-bottom: 2px solid #CCCCCC;
  margin: 30px 0 0;
  padding: 0 0 13px;
}

.sign-page__input:focus {
  content: "";
  outline: none;
}