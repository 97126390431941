.header__logo {
  width: 142px;
  height: 33px;
  object-fit: contain;
}

@media screen and (max-width: 490px) {
  .header__logo {
    width: 103.74px;
    height: 24.4px;
    padding-left: 27px;
  }
}