.popup__title {
  margin: 0;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  padding-top: 34px;
  padding-left: 36px;
  align-self: flex-start;
}

@media screen and (max-width: 420px) {
  .popup__title {
    padding-left: 22px;
    font-size: 22px;
    line-height: 24px;
  }
}

@media screen and (max-width: 360px) {
  .popup__title {
    padding-top: 25px;
    font-size: 18px;
    line-height: 22px;
  }
}