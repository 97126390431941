.header__logout-button {
  cursor: pointer;
  background-color: transparent;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #A9A9A9;
  border: 0;
  margin: 0;
  padding: 0;
  transition: opacity 0.5s ease;
}

.header__logout-button:hover {
  opacity: 0.6;
}