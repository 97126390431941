.profile__avatar-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  background-image: url("../../../images/Avatar-pen.svg");
  position: absolute;
  width: 120px;
  height: 120px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  transition: 0.5s;
  cursor: pointer;
  content: '';
  }
  
  .profile__avatar-overlay:hover {
    opacity: 1;
  }