.elements {
  width: 100%;
  max-width: 880px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(282px, 1fr));
  column-gap: 17px;
  row-gap: 20px;
}

@media screen and (max-width: 1024px) {
  .elements {
    width: calc(100% * (918 / 1024));
    }
  }

  @media screen and (max-width: 480px) {
    .elements {
      width: 100%;
      }
    }